<template>
  <form
    class="s-controls flex items-center justify-center bg-teal text-white h-10 rounded-full px-1 transition-all ease-in-out duration-200 s-shadow font-main text-lg"
    :class="{
      'w-10': !opened,
      'w-full': opened,
      'w-24': peek
    }"
    @click="rootClick"
    @pointerenter="pointerEnter"
    @pointerleave="pointerLeave"
  >
    <Transition
      name="fade"
      mode="out-in"
    >
      <!-- COMPONENT CLOSED STATE / STATIC -->
      <div
        v-if="!opened && !peek && !loading"
        key="component-closed-key"
        style="transition-duration: 50ms"
      >
        <button
          type="button"
          class="w-10 h-10 flex items-center justify-center"
          @click="handleAddOrOpen"
        >
          <!-- COMPONENT CLOSED STATE / NO ITEMS IN CART-->
          <span v-if="!itemIsInCart">
            <IconPlus
              width="14"
              class="mt-0.5"
            />
          </span>

          <!-- COMPONENT CLOSED STATE / HAS ITEMS IN CART-->
          <span v-if="itemIsInCart">{{ qtyInCart }}</span>
        </button>
      </div>

      <!-- COMPONENT CLOSED STATE / PEEK -->
      <div
        v-else-if="peek && !loading"
        key="component-closed-peek-key"
        class="w-full peek"
        style="transition-duration: 50ms"
      >
        <button
          type="button"
          class="flex w-full items-center justify-between uppercase text-base"
          @click="handleAddOrOpen"
        >
          <span class="pl-3">Add</span>
          <IconPlus
            width="14"
            class="mx-2 mt-0.5"
            @click="increment"
          />
        </button>
      </div>

      <!-- COMPONENT OPEN STATE / SELECT QTY -->
      <div
        v-else-if="opened && !submitting && !loading"
        key="select-qty-key"
        class="flex w-full items-center justify-between"
      >
        <button
          v-if="showIncDec"
          type="button"
          class="w-8 h-8 flex items-center justify-center bg-teal text-pink-50 rounded-full"
          @click="decrement"
        >
          <IconMinus
            width="14"
            class="mt-0.5"
          />
        </button>

        <div
          v-if="!showIncDec"
          class="w-8 h-8"
        >
          <button
            v-if="showIncDecRem"
            class="w-8 h-8 flex items-center justify-center bg-pink-100 text-black rounded-full"
            type="button"
            @click="decrement"
          >
            <IconClose
              width="14"
              class="mt-0.5"
            />
          </button>
        </div>
        <span>{{ adjustedQty }}</span>
        <button
          type="button"
          class="w-8 h-8 flex items-center justify-center bg-teal text-pink-50 rounded-full"
          @click="increment"
        >
          <IconPlus
            width="14"
            class="mt-0.5"
          />
        </button>
      </div>

      <!-- COMPONENT OPEN STATE / SUCCESS -->
      <div
        v-else-if="opened && submitting && !loading"
        key="success-qty-key"
        class="flex w-full items-center justify-center"
      >
        <IconCheckmarkBold width="24" />
      </div>

      <!-- LOADING STATE -->
      <div
        v-else-if="loading"
        key="loading-key"
        class="flex w-full items-center justify-center"
      >
        <IconLoadingFlowery width="24" class="mt-0.5" />
      </div>
    </Transition>
  </form>
</template>

<script setup>
  import { ref, computed, watch } from 'vue'
  import debounce from 'just-debounce-it'
  const MAX_ADD = 99

  const props = defineProps({
    qtyInCart: {
      type: Number,
      required: true
    },
    productCardHover: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  })

  const adjustedQty = ref(props.qtyInCart || 0)
  const eagerlyAdjustedQty = ref(-1)
  const adjustmentPending = ref(false)
  const opened = ref(false)
  const submitting = ref(false)
  const peek = ref(false)
  const hover = ref(false)

  const emit = defineEmits(['updateQty'])

  const currentQty = computed(() => (eagerlyAdjustedQty.value >= 0 ? eagerlyAdjustedQty.value : props.qtyInCart))

  const itemIsInCart = computed(() => currentQty.value > 0)
  const showIncDec = computed(() => adjustedQty.value > 1)
  const showIncDecRem = computed(() => adjustedQty.value === 1)

  watch(
    () => props.qtyInCart,
    () => {
      eagerlyAdjustedQty.value = -1
    }
  )

  watch(
    () => props.qtyInCart,
    (newQty) => {
      // Only sync if we're not adjusting or eagerly setting qty
      if (!adjustmentPending.value && eagerlyAdjustedQty.value < 0) {
        adjustedQty.value = newQty
      }
    }
  )

  watch(
    () => props.productCardHover,
    (mouseEnter, mouseLeave) => {
      if (mouseEnter) handleMouseEnter()
      if (mouseLeave) handleMouseLeave()
    }
  )

  watch(hover, (mouseEnter) => {
    if (mouseEnter) handleMouseEnter()
  })

  const rootClick = (e) => {
    e.preventDefault()
  }

  const submitFn = debounce(() => {
    console.log('submitting item with qty: ', adjustedQty.value)
    if (adjustedQty.value !== props.qtyInCart) {
      emit('updateQty', adjustedQty.value)
      submitting.value = true
    }
    close()
  }, 200)

  const submit = () => {
    eagerlyAdjustedQty.value = adjustedQty.value
    if (adjustedQty.value === 0) {
      if (adjustedQty.value !== props.qtyInCart) {
        emit('updateQty', adjustedQty.value)
      }
      close()
    } else {
      adjustmentPending.value = true
      submitFn()
    }
  }

  const cancelDebouncedSubmit = submitFn.cancel

  const open = () => {
    opened.value = true
    adjustedQty.value = currentQty.value
  }

  const closePeek = () => {
    peek.value = false
    opened.value = true
  }

  const close = () => {
    cancelDebouncedSubmit()
    opened.value = false
    submitting.value = false
    adjustmentPending.value = false
    adjustedQty.value = currentQty.value || 0
  }

  const increment = () => {
    if (adjustedQty.value < MAX_ADD) {
      adjustedQty.value++
      submit()
    }
  }

  const decrement = () => {
    if (adjustedQty.value >= 0) {
      adjustedQty.value--
      console.log(adjustedQty.value)
      submit()
    }
  }

  const handleAddOrOpen = () => {
    if (!itemIsInCart.value) {
      adjustedQty.value = 1
      emit('updateQty', adjustedQty.value)
      opened.value = true
    } else {
      opened.value = true
    }
  }

  const pointerEnter = (event) => {
    if (event.pointerType === 'touch') return
    hover.value = true
  }

  const pointerLeave = (event) => {
    if (event.pointerType === 'touch') return
    hover.value = false
  }

  const handleMouseEnter = () => {
    if (currentQty.value === 0 && !adjustmentPending.value) {
      peek.value = true
    } else {
      opened.value = true
    }
  }

  const handleMouseLeave = () => {
    if (!adjustmentPending.value) {
      opened.value = false
      peek.value = false
    }
  }
</script>

<style scoped lang="postcss">
  .fade-enter-active,
  .fade-leave-active {
    transition-timing-function: ease-out;
    transition-property: opacity;
    transition-duration: 200ms;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .s-shadow {
    box-shadow: 1px 2px 14px 0 rgba(0, 0, 0, 0.13);
  }
  .s-controls {
    max-width: theme('space.36');
  }
</style>