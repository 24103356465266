<template>
  <QuickAddToCartControls
    v-if="customerCanPurchase"
    :key="woocommerceId"
    :qty-in-cart="qtyInCart"
    :product-card-hover="productCardHover"
    :loading="loading"
    @update-qty="updateQty"
  />
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import { CartItem } from '~~/utils/cart-types'

const props = defineProps<{
  woocommerceId: string
  productCardHover: boolean
}>()
const emit = defineEmits<{
  (e: 'addToCart'): void
}>()

const store = useStore()
const xShoppingCart = computed(() => store.getters['cart/xShoppingCart'])
const xIsAuthenticated = computed(() => store.getters['authuser/xIsAuthenticated'])
const xSelectedLocationId = computed(() => store.getters['user-region/xSelectedLocationId'])
const customerCanPurchase = computed(() => customerHasLocationSelected.value && xIsAuthenticated.value)
const customerHasLocationSelected = computed(() => !!xSelectedLocationId.value)
const cartItem = computed(() => 
  xShoppingCart.value.items.find((item: CartItem) => item.id.toString() === props.woocommerceId)
)
const qtyInCart = computed(() => cartItem.value ? cartItem.value.quantity.value : 0)
const cartKey = computed(() => cartItem.value?.item_key ?? null)
const xAddToCart = (payload: any) => store.dispatch('cart/xAddToCart', payload)
const xRemoveFromCart = (payload: any) => store.dispatch('cart/xRemoveFromCart', payload)
const xUpdateProductQuantity = (payload: any) => store.dispatch('cart/xUpdateProductQuantity', payload)

const loading = ref(false)
const updateQty = async (qty: number) => {
  loading.value = true
  if (qty === 0) {
    await xRemoveFromCart({ item_key: cartKey.value })
  } else if (qtyInCart.value === 0) {
    await xAddToCart({
      productID: props.woocommerceId,
      quantity: qty
    })
    emit('addToCart')
  } else {
    if (qty >= qtyInCart.value) {
      emit('addToCart')            
    }  
    await xUpdateProductQuantity({
      itemKey: cartKey.value,
      quantity: qty
    })
  }
  loading.value = false
}
</script>